import React from 'react';
import './BlurbModal.css';

const BlurbModal = ({ show, onClose }) =>
{
    if (!show)
    {
        return null;
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Join Our Community!</h2>
                <p>Create an account to help us expand our ASL database by contributing video clips. Your participation will enhance our model's accuracy and help countless others learn ASL!</p>
                <button className="btn btn--primary btn--small" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default BlurbModal;

