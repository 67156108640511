import React from 'react'
import { SupportButton } from './Button'
import { Link } from 'react-router-dom'
import './Footer.css';
import { TbHandTwoFingers } from "react-icons/tb";

function Footer()
{
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    Contact The Team
                </p>
                <div className='input-areas'>
                    <form>
                        <SupportButton buttonStyle='btn--outline'>Send A Message</SupportButton>
                    </form>
                </div>
            </section>

            <div class='footer-link-page-items'>
                <Link className="footer-item" to='/testimonials'>Testimonials</Link>
                <Link className="footer-item" to='/privacy'>Privacy Policy</Link>
                <Link className="footer-item" to='/terms'>Terms of Service</Link>
            </div>
            
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                        <Link to='/' className='social-logo'>
                            ASL-Live
                            {/* <TbHandTwoFingers className="navbar-icon" /> */}
                        </Link>
                    </div>
                    <small class='website-rights'>ASL-Live © 2024 All Rights Reserved</small>
                    <div class='social-icons'>
                        <a
                            class='social-icon-link youtube'
                            target='_blank' rel='noopener noreferrer'
                            href="https://www.youtube.com/@ASL-Live"
                            aria-label='Youtube'
                        >
                            <i class='fab fa-youtube' />
                        </a>
                        <a
                            class='social-icon-link instagram'
                            target="_blank" rel="noopener noreferrer"
                            href="https://www.instagram.com/asl.live.io/"
                            aria-label='Instagram'
                        >
                            <i class='fab fa-instagram' />
                        </a>
                        <a
                            class='social-icon-link linkedin'
                            target="_blank" rel="noopener noreferrer"
                            href="https://www.linkedin.com/groups/13097723/"
                            aria-label='LinkedIn'
                        >
                            <i class='fab fa-linkedin' />
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
