import React, { useContext } from 'react'
import './Privacy.css'
import Navbar from "../Navbar"
import ThemeContext from '../ThemeContext';

export default function Privacy()
{
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className='privacy-page'>
                <div className="privacy-container">
                    <div className="privacy-content">
                        <h1>Privacy Policy</h1>
                        <p>At ASL-Live.com, we are committed to protecting your privacy and ensuring the security of any personal information you provide to us. This Privacy Policy outlines how we collect, use, store, and protect your personal information when you use our services. <br />By accessing or using ASL-Live.com, you agree to this Privacy Policy. If you do not agree with any part of this policy, please do not use our website.<br /><br /></p>
                        <h2>1. Information We Collect</h2>
                        <p>When you use ASL-Live.com, we collect the following types of personal information:<br /><br /></p>
                        <h3>Personal Information</h3>
                        <li>Name: When you create an account, we collect your name.</li>
                        <li>Email Address: We collect your email address during account registration.<br /><br /></li>
                        <h3>Content You Submit</h3>
                        <li>Recorded Video Clips: When you use our service to record and submit video clips of yourself signing, these recordings are collected and stored for the purpose of ompriving our ASL translation dataset.<br /><br /></li>
                        <h3>Automatically Collected Information</h3>
                        <p>When you visit ASL-Live.com, we may automatically collect information such as your IP address, browser type, device type, and usage data. This information is used for website performance, security, and analytical purposes.<br /><br /></p>
                        <h2>2. How We Use Your Information</h2>
                        <p>ASL-Live.com provides a free service that uses your webcam to offer real-time ASL translation. Signed-in users also have the ability to record and submit video clips of themselves signing to contribute to our data set for improving the service.<br /></p>
                        <li>Providing Our Services: To deliver the ASL translation services and maintain your account.</li>
                        <li>Improving The Service: Recorded video clips are used to improve our ASL translation models and enhance the accuracy of our tool.</li>
                        <li>Comunication: We may use your email address to send account-related notifications, updates, and service-related information.</li>
                        <li>Security And Protection: To protect our website, users, and services from unauthorized access, fraud, and abuse.</li><br />
                        <h2>3. How We Share Your Information</h2>
                        <p>We do not sell, rent, or trade your personal information to third parties. However, we may share your information in the following limited circumstances:</p>
                        <li>Service Providers: We may share your data with third-party service providers (e.g., hosting or analytics services) that assist us in operating the website and providing our services. These providers are obligated to protect your information and only use it for the purpose of providing their services.</li>
                        <li>Legal Requirements: We may disclose your information if required to do so by law, or if we believe that such action is necessary to comply with legal obligations or to protect the safety and rights of ASL-Live.com, its users, or others. <br /><br /></li>
                        <h2>4. How We Store and Protect Your Information</h2>
                        <p>We take the security of your personal information seriously and implement reasonable technical and organizational measures to protect it from unauthorized access, loss, or misuse.<br /></p>
                        <li>Data Retention: We will retain your personal information for as long as your account is active or as needed to provide you with our services. If you request the deletion of your account, your personal information will be deleted in accordance with our data retention policy.</li>
                        <li>Data Security: We use industry-standard encryption and security practices to safeguard your data, including secure storage and restricted access to your personal information.<br /><br /></li>
                        <h2>5. Your Rights and Choices</h2>
                        <p>You have certain rights regarding your personal information, including:</p>
                        <li>Access: You may request access to the personal information we hold about you.</li>
                        <li>Correction: You have the right to request correction of inaccurate or incomplete information.</li>
                        <li>Deletion: You may request the deletion of your account and personal information by contacting us.</li>
                        <li>Objection: You have the right to object to the processing of your personal information for certain purposes, such as direct marketing.</li>
                        <p>To exercise any of these rights, please contact us at support@asl-live.com.</p> <br />
                        <h2>6. Children's Privacy</h2>
                        <p>ASL-Live.com is intended for users aged 18 and older. We do not knowingly collect personal information from anyone under the age of 18. If we become aware that a user under the age of 18 has provided personal information, we will take steps to delete such information.<br /><br /></p>
                        <h2>7. Changes to This Privacy Policy</h2>
                        <p>We reserve the right to update or modify this Privacy Policy at any time. When we make changes, we will post the updated policy on this page and update the "Effective Date." Your continued use of ASL-Live.com after the changes constitutes your acceptance of the updated Privacy Policy.<br /><br /></p>
                        <h2>8. Contact Us</h2>
                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please email us at: support@asl-live.com</p>
                    </div>
                </div>
            </div>
        </div >

    );
}