import React, { useState, useContext, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth, db } from "../../firebase/firebase";
import { setDoc, doc } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Navbar from "../Navbar"
import './SignUp.css'
import ThemeContext from '../ThemeContext';
import { BlurbButton } from '../Button';
import BlurbModal from '../BlurbModal';

export default function SignUp() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState(""); // Add firstName state for registration
    const [lastName, setLastName] = useState(""); // Add lastName state for registration
    const [error, setError] = useState("");
    const [callError, setCallError] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false); // Default to login mode
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const navForgotPassword = () => {
        navigate('/forgot-password');
    };

    // Handle user registration
    const handleRegister = async (e) => {
        console.log("register")
        console.log(callError)
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log("Creating Firestore document for user:", user.uid);

            // Store additional user data in Firestore
            await setDoc(doc(db, "users", user.uid), {
                firstName: firstName,
                lastName: lastName,
                email: user.email,
                createdAt: new Date(),
            });

            await sendEmailVerification(user);
            console.log("User logged in: ", user);
            sessionStorage.setItem('isLoggedIn', true);
            sessionStorage.setItem('user', user.uid);
            navigate('/get-involved');
        } catch (error) {
            setError(error.message);
            setCallError(true);
            console.error("Error registering user:", error);
        }
    };

    // Handle user login
    const handleLogin = async (e) => {
        console.log("handling login")
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // if (!user.emailVerified) {
            //     setError("Please verify your email before logging in.");
            //     return;
            // }


            // save the user to session storage
            console.log("User logged in: ", user);
            sessionStorage.setItem('isLoggedIn', true);
            sessionStorage.setItem('user', user.uid);
            navigate('/get-involved');
        } catch (error) {
            setError(error.message);
            setCallError(true);
        }
    };

    // Toggle between login and registration forms
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        if (isRegistering) {
            await handleRegister(e);
        } else {
            await handleLogin(e);
        }
    };

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className="signup-container">

                <div className='branded-container'>
                    <form onSubmit={handleSubmit} className='branded-container'>
                        <div className='signup-title-container'>
                            <h1 className="signup-title">
                                {isRegistering
                                    ? "Create an Account 🤟"
                                    : "Welcome Back! 👋"}
                            </h1>
                        </div>

                        {isRegistering && (
                            <>
                                <div className='branded-input'>
                                    <TextField
                                        className='custom-width'
                                        id="outlined-basic"
                                        label="First Name"
                                        variant="outlined"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>

                                <div className='branded-input'>
                                    <TextField
                                        className='custom-width'
                                        id="outlined-basic"
                                        label="Last Name"
                                        variant="outlined"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </>
                        )}

                        <div className='branded-input'>
                            <TextField
                                className='custom-width'
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='branded-input'>
                            <TextField
                                className='custom-width'
                                error={callError}
                                type="password"
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                helperText={error}
                            />
                        </div>
                        {!isRegistering && (
                            <button type="button" className="forgot-password-link" onClick={navForgotPassword}>Forgot Your Password?</button>
                        )}
                        <div className='signup-button'>
                            <button type="submit" variant="contained" className='branded-button'>
                                {isRegistering ? "Register" : "Login"}
                            </button>
                        </div>
                        <div className="blurb-div">
                            {!showModal && isRegistering && (
                                <BlurbButton
                                    openModal={openModal}
                                    buttonStyle="btn--primary"
                                    buttonSize="btn--small"
                                />
                            )}
                            <BlurbModal show={showModal} onClose={closeModal} />
                        </div>
                        <p className="signin-link">
                            {isRegistering ? (
                                "Already have an account?"
                            ) : (
                                "Don't have an account?"
                            )}{" "}
                            <span
                                onClick={() => {
                                    setIsRegistering(!isRegistering)
                                    setCallError(false);
                                    setError('');
                                }}
                                style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }}
                            >
                                {isRegistering ? "Log in here" : "Create one here"}
                            </span>

                        </p>
                    </form>
                </div>

            </div>
        </div>

    );
}