import React, { useState, useEffect } from 'react';
import './WordList.css';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import VideoFetchedOverlay from './VideoFetchedOverlay';

export default function WordList({ onFolderSelect }) {
    const [folders, setFolders] = useState([]);
    const [videoURL, setVideoURL] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null); // Track selected folder

    // Utility function to format folder names
    const formatFolderName = (folderName) => {
        return folderName
            .replace(/_/g, ' ')  // Replace underscores with spaces
            .toLowerCase()       // Convert the whole string to lowercase
    };

    useEffect(() => {
        const fetchFolders = async () => {
            const storage = getStorage();
            const folderRef = ref(storage, '');

            try {
                const result = await listAll(folderRef);
                const folderNames = result.prefixes.map(folderRef => folderRef.name);
                setFolders(folderNames);
            } catch (error) {
                console.error("Error fetching folders:", error);
            }
        };

        fetchFolders();
    }, []);

    const loadFirstVideo = async (folderName) => {
        const storage = getStorage();
        const folderRef = ref(storage, folderName);

        try {
            const result = await listAll(folderRef);
            const videoFile = result.items.find(item => item.name.endsWith('.mp4'));

            if (videoFile) {
                const videoURL = await getDownloadURL(videoFile);
                setVideoURL(videoURL);
                console.log("First video URL:", videoURL);
            } else {
                console.log("No video files found in the folder.");
            }
        } catch (error) {
            console.error("Error loading video:", error);
        }
    };

    const handleClick = (folderName) => {
        console.log(`${folderName} button was clicked!`);
        if (onFolderSelect) {
            console.log("Calling onFolderSelect with:", folderName);
            onFolderSelect(folderName); // Notify parent component
        }
        setSelectedFolder(folderName); // Set selected folder for UI change
        loadFirstVideo(folderName);
    };

    const handleCloseOverlay = () => {
        setVideoURL(null); // Close the overlay by resetting the videoURL
    };


    return (
        <div className="word-list-box">
            <FixedSizeList
                height={400}
                width={200}
                itemSize={46}
                itemCount={folders.length}
                overscanCount={5}
                itemData={folders}
            >
                {({ index, style }) => {
                    const folderName = folders[index];
                    const isSelected = folderName === selectedFolder; // Check if it's the selected folder

                    return (
                        <ListItem
                            className="list-item"
                            key={index}
                            component="div"
                            style={style}  // Ensure proper virtualization
                        >
                            <ListItemButton
                                className="list-item-button"
                                onClick={() => handleClick(folderName)}
                                style={{
                                    // if selected change the color (this is temporary plz dont kill me)
                                    backgroundColor: isSelected ? '#1976d2' : '', 
                                    color: isSelected ? 'black' : '',  
                                }}
                            >
                                <ListItemText
                                    className="list-item-text"
                                    primary={formatFolderName(folderName)} // Apply formatting to folder name
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                }}
            </FixedSizeList>

            <VideoFetchedOverlay videoURL={videoURL} onClose={handleCloseOverlay} />
        </div>
    );
}
