import React from 'react';
import './Button.css'
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const LoginButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
    );
};

export const InfoButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to='/more-info' className='btn-mobile'>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
        </Link>
    );
};

export const DemoButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to='/demo' className='btn-mobile'>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
        </Link>
    );
};

export const SupportButton = ({ children, type, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to='/support' className='btn-mobile'>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}>
                {children}
            </button>
        </Link>
    );
};

export const CameraButton = ({ children, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}>
            {children}
        </button>
    );
};

export const LearnMoreButton = ({ children, onClick, buttonStyle, buttonSize }) =>
{
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    const handleLearnMoreClick = () =>
    {
        window.open("https://www.sciencedirect.com/science/article/pii/S0957417422020115", "_blank");
    };

    return (
        <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={handleLearnMoreClick}>
            {children}
        </button>
    );
};


export const RecordButton = ({ children, onClick, disabled, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </button>
    );
};



export const SendToCloudButton = ({ handleVideoUpload, hasRecordedChunks, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button 
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={handleVideoUpload} disabled={!hasRecordedChunks}>
            Submit
        </button>
    );
};

export const SendToTranslateButton = ({ handleVideoUpload, hasRecordedChunks, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button 
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={handleVideoUpload} disabled={!hasRecordedChunks}>
            Submit
        </button>
    );
};

export const CancelVidButton = ({ sendToCloud, hasRecordedChunks, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button 
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={sendToCloud} disabled={!hasRecordedChunks}>
            Send to Cloud
        </button>
    );
};

export const TranslateVideoButton = ({ handleUpload, hasVideoFile, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={handleUpload}
            disabled={!hasVideoFile} // Disable if no video file
        >
            Submit
        </button>
    );
};

export const InstructionButton = ({ openModal, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button 
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={openModal}>
            Instructions
        </button>
    );
};

export const BlurbButton = ({ openModal, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? STYLES[0] : buttonStyle;
    const checkButtonSize = SIZES.includes(buttonSize) ? SIZES[0] : buttonSize;

    return (
        <button 
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={openModal}>
            Why Should I Create An Account?
        </button>
    );
};