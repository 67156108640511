import React, { useContext } from 'react'
import './MoreInfo.css'
import Navbar from "../Navbar"
import ThemeContext from '../ThemeContext';

const steps = [
    {
        title: "Identifying Landmarks",
        description: "First, when you sign in front of the webcam, our system uses OpenCV, a powerful library for image and video processing, to break down your video into 30 separate frames. At the same time, MediaPipe, a framework developed by Google for building multimodal applied machine learning pipelines, identifies key points on your hands in each frame. These key points recognize ASL signs accurately."
    },
    {
        title: "Decoding the Signs",
        description: "Next, our custom algorithm takes over. Our implementation looks at the hand landmarks provided by MediaPipe and determines which signs are being made. Each identified sign is converted into a string of text that represents a word or a phrase in American Sign Language (ASL). The text is saved into an array to be sent to our custom API. This step is crucial for bridging the gap between visual signs and textual representation."
    },
    {
        title: "Translating the Results",
        description: "Finally, we turn these ASL terms, known as ‘GLOSS’, into plain English. We use OpenAI’s powerful GPT-3 model, a cutting-edge artificial intelligence known for its ability to understand and generate human-like text, to translate the sequence of ASL glosses into a coherent English sentence. This translation is then swiftly sent back to the website, allowing you to see the English version of your signed sentence in real time."
    }
];

export default function MoreInfo()
{
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className="more-info-page">
                <div className="more-info-container">
                    <div className="more-info-content">
                        <h1>How Does It Work?</h1>
                        <h2>Technical Details of Our Tool</h2>
                        <img src="/images/mlmodel.png" alt="Technical Blueprint of ASL-Live" className="more-info-image" />
                        <h3>In this diagram, we observe a high-level process of how our machine learning model for ASL-Live works.<br /><br /></h3>
                        {/* <p>(1) First, once a user has sent a video clip of their signs, OpenCV then separates the recording into 30 frames, where then MediaPipe creates landmarks on the frames and extracts points from the hands. <br /><br /> (2) Then, our algorithm separates each found ASL sign into an array of strings. <br /><br />(3) Finally, our model calls the OpenAI API to convert our "GLOSS" language to English text. That sentence is then sent back to the frontend so the user can view what was processed. This happens in real time.</p> */}
                        <div className="steps-container">
                            {steps.map((step, index) => (
                                <div key={index} className="info-card">
                                    <h3>{step.title}</h3>
                                    <p>{step.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}