import React, {useContext} from 'react'
import './Terms.css'
import Navbar from "../Navbar"
import ThemeContext from '../ThemeContext';

export default function Terms()
{
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';
    
    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className='terms-page'>
                <div className="terms-container">
                    <div className="terms-content">
                        <h1>Terms and Conditions</h1>
                        <p>Welcome to ASL-Live.com! These Terms and Conditions govern your use of our website, which provides real-time translation of American Sign Language (ASL) using your webcam. By accessing or using our services, you agree to comply with these terms. If you do not agree, you should not use our website.<br /><br /></p>
                        <h2>1. Acceptance of Terms</h2>
                        <p>By signing up and using ASL-Live.com, you agree to these Terms and Conditions. You must be 18 years of age or older to create an account and use our services. If you are under 18, you are not permitted to use the platform.<br /><br /></p>
                        <h2>2. Service Description</h2>
                        <p>ASL-Live.com provides a free service that uses your webcam to offer real-time ASL translation. Signed-in users also have the ability to record and submit video clips of themselves signing to contribute to our data set for improving the service.<br /><br /></p>
                        <h2>3. User Conduct and Responsibilities</h2>
                        <p>When using ASL-Live.com, you are required to adhere to the following guidelines:
                            <li>Appropriate Behavior: Users must be fully clothed and behave in a respectful and appropriate manner while using the webcam feature. Signing inappropriate, offensive, or harmful content is strictly prohibited.</li>
                            <li>Accurate and Appropriate Signs: Users are expected to sign accurately and appropriately. Content that is inaccurate, harmful, or offensive will not be tolerated.</li>
                            <li>User Submissions: By submitting recorded video clips, you agree that your content may be used to improve ASL-Live.com's dataset. Submitted content should meet the appropriate and accurate signing guidelines outlined above.</li><br /></p>
                        <h2>4. Account Creation</h2>
                        <p>To create an account, you must provide a valid name and email address. You agree to provide truthful information during registration. We reserve the right to suspend or terminate accounts that provide false information or violate any aspect of these Terms and Conditions.<br /><br /></p>
                        <h2>5. Data Collection and Privacy</h2>
                        <p>By using ASL-Live.com, you consent to the collection and use of the following personal data:
                            <li>Name and Email: We collect your name and email address when you create an account.</li>
                            <li>Recorded Video Clips: Any video clips you record and submit through the platform will be collected and stored in our database for the purpose of enhancing the ASL dataset.</li>
                            For more details about how we handle your data, please refer to our Privacy Policy.<br /><br /></p>
                        <h2>6. Intellectual Property</h2>
                        <p>By using ASL-Live.com, you consent to our intellectual property:
                            <li>Ownership: ASL-Live.com owns all rights to the content and services provided on the platform, including the technology used to offer ASL translation.</li>
                            <li>User Submissions: By submitting video clips, you grant ASL-Live.com a worldwide, non-exclusive, royalty-free license to use, modify, distribute, and display the content for the purpose of improving the service. You retain ownership of your original recordings.</li><br /></p>
                        <h2>7. Prohibited Activities</h2>
                        <p>You agree not to engage in any of the following prohibited activities:
                            <li>Misusing the webcam feature by recording or transmitting inappropriate, illegal, or offensive content.</li>
                            <li>Harassing or abusing other users of the platform.</li>
                            <li>Attempting to hack, reverse-engineer, or interfere with the operation of ASL-Live.com.</li><br /></p>
                        <h2>8. Account Termination</h2>
                        <p>We reserve the right to suspend or terminate your account if we determine that you have violated these Terms and Conditions. Users who submit inappropriate, harmful, or offensive content will be subject to immediate termination of their accounts without notice.<br /><br /></p>
                        <h2>9. Disclaimer of Warranties</h2>
                        <p>ASL-Live.com is provided "as-is" without any warranties. We do not guarantee that the service will be uninterrupted, error-free, or free from security breaches. We disclaim all liability for any issues that may arise from your use of the platform, including any issues related to the accuracy of the ASL translations.<br /><br /></p>
                        <h2>10. Limitation of Liability</h2>
                        <p>To the maximum extent permitted by law, ASL-Live.com shall not be liable for any damages arising out of your use or inability to use the service. This includes direct, indirect, incidental, or consequential damages.<br /><br /></p>
                        <h2>11. Governing Law</h2>
                        <p>These Terms and Conditions are governed by and construed in accordance with the laws of the United States of America. Any disputes arising from these terms or the use of ASL-Live.com shall be resolved in accordance with the laws of this jurisdiction.<br /><br /></p>
                        <h2>12. Modifications to the Terms</h2>
                        <p>We reserve the right to update or modify these Terms and Conditions at any time. Changes will be posted on this page with an updated "Effective Date." Your continued use of ASL-Live.com after any changes constitutes your acceptance of the new terms.<br /><br /></p>
                        <h2>13. Contact Information</h2>
                        <p>If you have any questions or concerns regarding these Terms and Conditions, please email us at: support@asl-live.com</p>
                    </div>
                </div>
            </div>
        </div>

    );
}