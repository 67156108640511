import React, {useContext} from 'react'
import './Tutorial.css'
import { DemoButton } from '../Button';
import Navbar from '../Navbar';
import ThemeContext from '../ThemeContext';

export default function Tutorial()
{
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className="tutorial-page">
                <div className="tutorial-container">
                    <div className="tutorial-content">
                        <h1>Tutorial Video</h1>
                        <iframe
                            src="https://www.youtube.com/embed/-9Ado8D3A-w"
                            title="Tutorial Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="tutorial-video"
                        ></iframe>
                        <DemoButton buttonStyle="btn--primary" buttonSize="btn--large">
                            Try It Out!
                        </DemoButton>
                    </div>
                </div>
            </div>
        </div>
    );
}