import React, {useContext} from 'react'
import Reviews from '../Reviews';
import Navbar from "../Navbar"
import './Testimonials.css'
import ThemeContext from '../ThemeContext';

function TestimonialsPage() {
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className='testimonials'>
                <h1>Testimonials</h1>
                <h2>Here's what our esteemed supporters have to say about ASL-Live!</h2>
                <Reviews />
            </div>
        </div>
        
    );
}

export default TestimonialsPage;