import React, { useState, useRef, useEffect } from 'react';
import './Access.css';
import WebcamComponent from '../Webcam';
import { CameraButton } from '../Button';

const Access = () =>
{
    const [isCameraActive, setCameraActive] = useState(false);

    const toggleCamera = () =>
    {
        setCameraActive(!isCameraActive);
    };

    const videoRef = useRef(null);

    useEffect(() => {
        if (isCameraActive && videoRef.current) {
            const stream = videoRef.current.srcObject;
            if (stream) {
                //startRecording(stream); // Start recording when the camera is active
            }
        } else if (!isCameraActive && videoRef.current) {
            // Stop the camera when it's toggled off
            const stream = videoRef.current.srcObject;
            if (stream) {
                stream.getTracks().forEach(track => track.stop()); // Stop all tracks
                videoRef.current.srcObject = null; // Clear the video source
            }
        }
    }, [isCameraActive]);

    return (
        <div className="access-page">
            <div className="access-content">
                <p>Directions: Follow the instructions to calibrate your camera.</p>
            </div>
            <div className="webcam-container">
            <h1>Accessing Your Camera</h1>
            <h2>Calibration made easy</h2>
                <WebcamComponent isActive={isCameraActive} onUserMedia={() => console.log("Webcam started")} videoRef={videoRef} onUserMediaError={() => console.log("Webcam error")} />
                <CameraButton onClick={toggleCamera} buttonStyle="btn--outline" children={isCameraActive ? "Turn Camera Off" : "Turn Camera On"} />
            </div>
            <div className="access-content">
                <p><strong>Disclaimer:</strong> In order for our product to work, we need access to your camera so that we may be able to detect your hand and face gestures. It is our utmost priority that your privacy is safe and your data is secure. ASL-Live will never save or share your private information online.</p>
            </div>
        </div>
    );
};

export default Access;