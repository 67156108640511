import OpenAI from 'openai';
import './Lpp.css';
import Button from '@mui/material/Button';

// Get OpenAI API Key from environment variables
const openAiApiKey = process.env.REACT_APP_OPENAI_API_KEY;

// Instantiate OpenAI client
const llm = new OpenAI({
    apiKey: openAiApiKey,
    dangerouslyAllowBrowser: true
});

// Function to make the API call
async function CallModel(template) {
    try {
        const chatCompletion = await llm.chat.completions.create({
            messages: [{ role: 'user', content: template }],
            model: 'gpt-4o-mini',
            temperature: 0,
        });
        console.log('Chat completion:', chatCompletion);
        return chatCompletion;
    } catch (error) {
        console.error('Error in API call:', error);
        throw error;
    }
}

async function Lpp(subject) {
    const template = `Goal: You are a machine that helps fill in missing grammatical articles from a machine-learning model that translates ASL to text. 
    Do not add additional meaning to the sentences, only fill in the precieved missing words. ASL requires a concatenation of words as signs in order to complete a sentence. assuming you are a concatenator, finish the sentences based off of the words being signed in a list in the form of {word1, word2, .. etc}
    only give me the finished sentence.
    String: ${subject}
`;

    try {
        const result = await CallModel(template);
        console.log('LPP Result:', result);
        return result;
    } catch (error) {
        console.error('Error in Lpp function:', error);
        return null;
    }
}
export default Lpp;

