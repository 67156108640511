// Import the Firebase SDKs
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";  // Firestore SDK
import { getStorage } from "firebase/storage";   
import { getAuth } from "firebase/auth"   // Firebase Storage SDK
import { getFunctions } from "firebase/functions";  // Firebase Functions SDK

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Services for Firestore and Functions
const db = getFirestore(app);         // Firestore Database
// const functions = getFunctions(app);  // Firebase Functions
const auth = getAuth(app);

// Initialize Firebase Storage with the custom bucket (override storage bucket)
const storage = getStorage(app, "gs://asl_training");  // Points to the `asl_training` bucket

export { db, auth, storage };