import React from 'react';
import './VideoFetchedOverlay.css'; // Create a CSS file for styling the overlay

const VideoFetchedOverlay = ({ videoURL, onClose }) => {
    if (!videoURL) return null; // Don't render if there's no video URL

    return (
        <div className="video-overlay" onClick={onClose}>
            <div className="video-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>
                    &times; {/* Close button */}
                </button>
                {/* autoPlay should work better than my previous .play func */}
                <video className="styled-video" controls autoPlay src={videoURL} />
            </div>
        </div>
    );
};

export default VideoFetchedOverlay;