import React, { useState, useEffect, useRef } from 'react';
import './ExtraContent.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SvgIcon from '@mui/material/SvgIcon';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BoltIcon from '@mui/icons-material/Bolt';
import { socket } from '../../socket';
import { io } from 'socket.io-client';
import { Link } from 'react-router-dom'
import Lpp from "../Lpp";

export default function ExtraContent({ translatedWords }) {
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const socketRef = useRef(null); // To store the socket connection
    const peerConnectionRef = useRef(null); // To store the peer connection
    const localStreamRef = useRef(null); // To store local media stream
    const remoteStreamRef = useRef(null); // To store remote media stream
    const localVideoRef = useRef(null); // Reference to the local video element
    const remoteVideoRef = useRef(null); // Reference to the remote video element
    const [didOffer, setDidOffer] = useState(false);
    
    function getIcon() {
        return output ? AutorenewIcon : BoltIcon;
    }
    function getText() {
        return output ? 'Clear Response' : 'Get Response';
    };   


    let peerConfiguration = {
        iceServers: [
            {
                urls: 'stun:stun.l.google.com:19302'
            },
            {
                urls: 'stun:stun1.l.google.com:19302'
            },
            {
                urls: 'turn:45.55.71.79:3478?transport=udp',
                username: 'ASL-LIVE-BACKEND',
                credential: 'qqwerty13u154235125fr1ew2sdfvgb12hj54125y'
            },
            {
                urls: 'turn:45.55.71.79:3478?transport=tcp',
                username: 'ASL-LIVE-BACKEND',
                credential: 'qqwerty13u154235125fr1ew2sdfvgb12hj54125y'
            }
        ]
    };
    

    const HandleCall = async () => {
        if (!output && input) {
            setIsProcessing(true); // Start processing state
            console.log('Words being post processed:', input)
            const result = await Lpp(input); // Make the API call with input

            // Store the API response output
            setOutput(result.choices[0].message.content);;
            console.log('Result:', result.choices[0].message.content);
            setIsProcessing(false); // End processing state
        } else {
            // Clear both input and output if we are resetting
            console.log('else');
            setInput('');
            setOutput('');
        }
    };

    const userName = "ASL-LIVE"+Math.floor(Math.random() * 50);
    const HandleVideoCall = async () => {
        const password = "x";

        // Make sure our peer connection is closed before starting a new call
        if (peerConnectionRef.current) {
            console.log("IT WAS ALIVE")
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
        }

        // Clear out socket table before making the call
        try {
            const response = await fetch('https://ec2-3-14-79-47.us-east-2.compute.amazonaws.com:8000/clear', {
                method: 'GET'
            });
            
            if (response.ok) {
                console.log("GET Clear socket table call success!");
            } else {
                console.error("GET /clear request failed");
            }
        } catch (err) {
            console.error("Error making GET request to /clear:", err);
        }

        await fetchUserMedia();
        createPeerConnection();

        try { 
            socketRef.current = io.connect('https://ec2-3-14-79-47.us-east-2.compute.amazonaws.com:8000/', {
                transports: ['websocket'],
                auth: {
                    userName,
                    password
                }
            });   
            socketRef.current.on('connect_error', (err) => {
                console.error('Socket connection error:', err);
            });
            // Handle received offers (second user)
            socketRef.current.on('newOfferAwaiting', (offer) => {
                console.log('newOfferAwaiting')
                handleOffer(offer, userName);   
            });
        
            // Listen for ICE candidates
            socketRef.current.on('receivedIceCandidateFromServer', (iceCandidate) => {
                try {
                    console.log('receivedIceCandidateFromServer')
                    addIceCandidate(iceCandidate);
                } catch(err) {
                    console.error("ERROR: ", err);
                }
                
            });

            socketRef.current.on('peerDisconnected', () => {
                console.log('The other peer has hung up.');
                
                // Close the peer connection if still active
                if (peerConnectionRef.current) {
                    console.log("WE ARE CLOSED FOR BUSINESSSSSS");
                    peerConnectionRef.current.close();
                    peerConnectionRef.current = null;
                }
    
                // Stop the local media tracks if necessary
                if (localStreamRef.current) {
                    localStreamRef.current.getTracks().forEach(track => track.stop());
                    localStreamRef.current = null;
                }
    
                // Clear the remote video stream
                if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
                    remoteVideoRef.current.srcObject = null;
                }
            });

            socket.on('receivedIceCandidateFromServer',iceCandidate=>{
                console.log('receivedIceCandidateFromServer')
                addIceCandidate(iceCandidate)
                console.log(iceCandidate)
            })
            
            const offer = await peerConnectionRef.current.createOffer();
            await peerConnectionRef.current.setLocalDescription(offer);
            setDidOffer(true);
            console.log(offer.type);
            socketRef.current.emit('newOffer', offer);
        } catch (err) {
            console.error('Error during video call offer:', err);
        }
    };

    const fetchUserMedia = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            });
            localStreamRef.current = stream;
            if (localVideoRef.current) {
                localVideoRef.current.srcObject = stream;
            }
        } catch (err) {
            console.error('Error accessing media devices:', err);
        }
    };

    const createPeerConnection = () => {
        console.log(userName)
        peerConnectionRef.current = new RTCPeerConnection(peerConfiguration);

        localStreamRef.current.getTracks().forEach(track => {
            peerConnectionRef.current.addTrack(track, localStreamRef.current);
        });

        peerConnectionRef.current.onicecandidate = (event) => {
            
            if (event.candidate) {
                const candidateType = event.candidate.type;

                // Check if it’s a relay candidate
                if (candidateType === 'relay') {
                    console.log("Relay candidate found:", event.candidate);
                }
                socketRef.current.emit('sendIceCandidateToSignalingServer', {
                    iceCandidate: event.candidate,
                    iceUserName: userName,
                    didOffer
                });
            }
        };
    };

    const handleOffer = async (offerObj, answerUserName) => {
        console.log("Received offer from another user:", offerObj);
        
        // Ensure offerObj is structured correctly and avoid accessing invalid fields
        const offerData = Array.isArray(offerObj) ? offerObj[0] : offerObj;
    
        if (!offerData.offer) {
            console.error("offerData.offer: OFFER", offerData);
            return;
        }
        if (!offerData.offer.sdp) {
            console.error("Missing SDP in offer", offerData);
            return;
        }
        if (!offerData.offer.type || !['offer', 'answer', 'pranswer', 'rollback'].includes(offerData.offer.type)) {
            console.error("Invalid or missing offer type", offerData);
            return;
        }
        
        // Fetch local media for the second user (answerer)
        await fetchUserMedia();
        
        // Create peer connection
        createPeerConnection();
    
        try {
            // Set the remote description to the received offer (from the first user)
            await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription({
                type: offerData.offer.type,
                sdp: offerData.offer.sdp
            }));
            
            // Create an answer and set it as the local description
            const answer = await peerConnectionRef.current.createAnswer();
            await peerConnectionRef.current.setLocalDescription(answer);
            
            // Send the answer back to the offerer via the signaling server
            offerObj[0].offererUserName = offerData.offererUserName;
            offerObj[0].answer = answer;
            const offerIceCandidates = await socketRef.current.emitWithAck('newAnswer', offerObj[0]);
            offerIceCandidates.forEach(c=>{
                peerConnectionRef.current.addIceCandidate(c);
                console.log("======Added Ice Candidate======")
            })
            console.log(offerIceCandidates);
            console.log("Sent answer back to the offerer");
            
        } catch (err) {
            console.error('Error creating answer:', err);
        }
    };    

    const addIceCandidate = (iceCandidate) => {
        try {
            peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(iceCandidate));
        } catch(err) {
            console.error(err)
        }
    };

    const handleHangUp = async () => {
        // Close the peer connection
        if (peerConnectionRef.current) {
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
            console.log("Peer connection closed.");
        }
    
        // Stop all local media tracks
        if (localStreamRef.current) {
            localStreamRef.current.getTracks().forEach(track => track.stop());
            localStreamRef.current = null;
            console.log("Local media tracks stopped.");
        }
    
        // Clear the remote video stream
        if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
            remoteVideoRef.current.srcObject = null;
            console.log("Remote video cleared.");
        }
    
        // Inform the other peer that the call has ended
        if (socketRef.current) {
            socketRef.current.emit('hangup');
            console.log("Hangup signal sent to other peer.");
        }

        try {
            const response = await fetch('https://ec2-3-14-79-47.us-east-2.compute.amazonaws.com:8000/clear', {
                method: 'GET'
            });
            
            if (response.ok) {
                console.log("GET Clear socket table call success!");
            } else {
                console.error("GET /clear request failed");
            }
        } catch (err) {
            console.error("Error making GET request to /clear:", err);
        }
    };

    return (
        <>
            <div className="extra-content-container">
                <h1>LPP OpenAI Integration Test</h1>
                <div className='main-container'>
                    <div className='main-input'>
                        <TextField
                            id="outlined-basic"
                            label="Sentence"
                            variant="outlined"
                            onChange={(e) => setInput(e.target.value)}
                            value={input}
                        />
                    </div>
                    <div className='main-output'>
                        {output}
                    </div>
                    <div className='main-button-container'>
                        <Button className='ai-button' variant="contained" onClick={HandleCall}>
                            {getText()} <SvgIcon component={getIcon()} inheritViewBox />
                        </Button>
                    </div>
                </div>
            </div>

            <div className='extra-content-container-video'>
                <h1>Peer Connection Test</h1>
                <div className='main-container-video'>
                    <video ref={localVideoRef} autoPlay playsInline></video>
                    <video ref={remoteVideoRef} autoPlay playsInline></video>
                </div>
                <button className="btn btn-primary col-1" onClick={HandleVideoCall}>Call!</button>
                <button className="col-1 btn btn-primary" onClick={handleHangUp}>Hangup</button>

                <Link className="col-1 btn btn-primary" to='/backend'>Backend Test For WebRTC</Link>
            </div>
        </>
    );
}
