import React from 'react'
import './App.css';
import Navbar from "./components/Navbar"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// these are the defined pages that you must import to the app
import Home from './components/pages/Home';
import Tutorial from './components/pages/Tutorial';
import MoreInfo from './components/pages/MoreInfo';
import SignUp from './components/pages/SignUp';
import ForgotPassword from './components/pages/ForgotPassword';
import Demo from './components/pages/Demo';
import About from './components/pages/About';
import Support from './components/pages/Support';
import Access from './components/pages/Access';
import Testimonials from './components/pages/Testimonials';
import ExtraContent from './components/pages/ExtraContent';
import GetInvolved from './components/pages/GetInvolved';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';
import Backend from './components/pages/Backend';


function App()
{
  return (
    <>
      <Router>
        <Routes>
          {/* these routes direct to each corresponding page found in the pages folder*/}
          <Route path="/" exact element={<Home/>}/>
          <Route path="/tutorial" exact element={<Tutorial/>}/>
          <Route path="/more-info" exact element={<MoreInfo/>}/>
          <Route path="/sign-up" exact element={<SignUp/>}/>
          <Route path="/forgot-password" exact element={<ForgotPassword/>}/>
          <Route path="/demo" exact element={<Demo/>}/>
          <Route path="/about" exact element={<About/>}/>
          <Route path="/support" exact element={<Support/>}/>
          <Route path="/access" exact element={<Access/>}/>
          <Route path="/testimonials" exact element={<Testimonials/>}/>
          <Route path="/extra-content" exact element={<ExtraContent/>}/>
          <Route path="/get-involved" exact element={<GetInvolved/>}/>
          <Route path="/terms" exact element={<Terms/>}/>
          <Route path="/privacy" exact element={<Privacy/>}/>
          <Route path="/backend" exact element={<Backend/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
