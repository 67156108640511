import React, { useRef, useEffect } from 'react';
import './NewVideoPreview.css';

const NewVideoPreview = ({ videoUrl, onCancel, children }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, [videoUrl]);

    return (
        <div className="video-preview-container">
            <div className="video-wrapper">
                <video 
                    className="video-preview" 
                    controls 
                    src={videoUrl} 
                    ref={videoRef}
                    controlsList="nofullscreen"
                />
                <h2 className="video-title">Preview your video:</h2>
            </div>
            <div className="preview-buttons-container">
                <button class="btn btn--primary btn--medium" onClick={onCancel}>Cancel</button>
                {children && (
                    <div className="send-to-cloud-button">
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewVideoPreview;
