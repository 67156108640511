import React, { useState, useEffect } from 'react';
import './Reviews.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const testimonials = [
  {
    name: 'Kanye West',
    review: 'Let\'s have a toast for the douche bags. Let\'s have a toast for the assholes. Let\'s have a toast for the scumbags. Every one of them that I know. Let\'s have a toast for the jerk offs. That\'ll never take work off. Baby, I got a plan. Run away fast as you can. She find pictures in my email. I sent this bitch a picture of my dick. I don\'t know what it is with females. But I\'m not too good at that shit. See, I could have me a good girl. And still be addicted to them hood rats. And I just blame everything on you. At least you know that\'s what I\'m good at. And I always find. Yeah, I always find. Yeah, I always find somethin\' wrong. You been puttin\' up wit\' my shit just way too long. I\'m so gifted at findin\' what I don\'t like the most. So I think it\'s time (so I think it\'s time). For us to have a toast.'
  },
  {
    name: 'Taylor Swift',
    review: 'I stay out too late. Got nothing in my brain. That\'s what people say, mm-mm. That\'s what people say, mm-mm. I go on too many dates. But I can\'t make \'em stay. At least that\'s what people say, mm-mm. That\'s what people say, mm-mm. But I keep cruisin\'. Can\'t stop, won\'t stop movin\'. It\'s like I got this music in my mind. Sayin\' it\'s gonna be alright. Cause the players gonna play, play, play, play, play. And the haters gonna hate, hate, hate, hate, hate. Baby, I\'m just gonna shake, shake, shake, shake, shake. I shake it off, I shake it off (hoo-hoo-hoo). Heartbreakers gonna break, break, break, break, break. And the fakers gonna fake, fake, fake, fake, fake. Baby, I\'m just gonna shake, shake, shake, shake, shake. I shake it off, I shake it off (hoo-hoo-hoo)'
  },
  {
    name: 'Scott Mescudi',
    review: 'Crush a bit, little bit. Roll it up, take a hit. Feeling lit, feeling right. Two AM, summer night, I don\'t care. Hand on the wheel. Driving drunk I\'m doing my thang. Rolling the Midwest side and out. Living my life, getting our dreams. People told me slow my roll. I\'m screaming out, "Fuck that". I\'ma do just what I want. Looking ahead no turning back. If I fall if I die. Know I lived it to the fullest. If I fall if I die. Know I lived and missed some bullets. I\'m on the pursuit of happiness and I know. Everything that shine ain\'t always gonna be gold, hey. I\'ll be fine once I get it, yeah, I\'ll be good. I\'m on the pursuit of happiness and I know. Everything that shine ain\'t always gonna be gold, hey. I\'ll be fine once I get it, yeah, I\'ll be good'
  },
  {
    name: 'O.J. Simpson',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!'
  },
  {
    name: 'Peter Griffin',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!'
  },
  {
    name: 'Donald Trump',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!'
  },
  {
    name: 'Rick Leinecker',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!'
  },
  {
    name: 'Arup Guha',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!'
  },
  {
    name: 'Will Smith',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!'
  },
  {
    name: 'Helen Keller',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!'
  },
];

function Reviews()
{
    const [slidesPerView, setSlidesPerView] = useState(3);

    useEffect(() =>
    {
        function handleResize()
        {
            if (window.innerWidth < 768)
            {
                setSlidesPerView(1);
            } else if (window.innerWidth < 1024)
            {
                setSlidesPerView(2);
            } else
            {
                setSlidesPerView(3);
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='testimonials-page'>
            <div className='testimonials-container'>
                <Swiper className="mySwiper"
                    modules={[Pagination]}
                    slidesPerView={slidesPerView}
                    spaceBetween={30}
                    pagination={{
                        clickable: true
                    }}>
                    {
                        testimonials.map(({ name, review }, index) =>
                        {
                            return (
                                <SwiperSlide key={index} className='testimonial'>
                                    <h3 className='client-name'>{name}</h3>
                                    <small className='client-review'>
                                        {review}
                                    </small>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default Reviews;