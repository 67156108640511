import React, { useEffect, useRef } from 'react';
import './ExtraContent.css'
import { socket } from '../../socket';
import { io } from 'socket.io-client';
import { useState } from "react"

export default function Backend() {
    const socketRef = useRef(null); // To store the socket connection
    const peerConnectionRef = useRef(null); // To store the peer connection
    const localStreamRef = useRef(null); // To store local media stream
    const remoteStreamRef = useRef(null); // To store remote media stream
    const localVideoRef = useRef(null); // Reference to the local video element
    const remoteVideoRef = useRef(null); // Reference to the remote video element
    const [didOffer, setDidOffer] = useState(false);
    const userName = "ASL-LIVE-BACKEND";
    
    let peerConfiguration = {
        iceServers: [
            {
                urls: 'stun:stun.l.google.com:19302'
            },
            {
                urls: 'stun:stun1.l.google.com:19302'
            },
            {
                urls: 'turn:45.55.71.79:3478?transport=udp',
                username: 'ASL-LIVE-BACKEND',
                credential: 'qqwerty13u154235125fr1ew2sdfvgb12hj54125y'
            },
            {
                urls: 'turn:45.55.71.79:3478?transport=tcp',
                username: 'ASL-LIVE-BACKEND',
                credential: 'qqwerty13u154235125fr1ew2sdfvgb12hj54125y'
            }
        ]
    };
    
    const HandleVideoCall = async () => {
        const password = "x";
        // Make sure our peer connection is closed before starting a new call
        if (peerConnectionRef.current) {
            console.log("IT WAS ALIVE")
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
        }

        //await fetchUserMedia();
        createPeerConnection();

        try {
            
            socketRef.current = io.connect('https://ec2-3-14-79-47.us-east-2.compute.amazonaws.com:8000/', {
                transports: ['websocket'],
                auth: {
                    userName,
                    password
                }
            });   
            socketRef.current.on('connect_error', (err) => {
                console.error('Socket connection error:', err);
            });

            // Handle answer response from the first user (offerer)
            socketRef.current.on('answerResponse', (answerObj) => {
                console.log('answerResponse')
                handleAnswer(answerObj);
            });
        
            // Listen for ICE candidates
            socketRef.current.on('receivedIceCandidateFromServer', (iceCandidate) => {
                console.log('receivedIceCandidateFromServer')
                addIceCandidate(iceCandidate);
            });
    
            if (socketRef.current) {
                socketRef.current.on('peerDisconnected', () => {
                    console.log('The other peer has hung up.');
                    
                    // Close the peer connection if still active
                    if (peerConnectionRef.current) {
                        peerConnectionRef.current.close();
                        peerConnectionRef.current = null;
                    }
        
                    // Stop the local media tracks if necessary
                    if (localStreamRef.current) {
                        localStreamRef.current.getTracks().forEach(track => track.stop());
                        localStreamRef.current = null;
                    }
        
                    // Clear the remote video stream
                    if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
                        remoteVideoRef.current.srcObject = null;
                    }
                });
            }
            
            socket.on('receivedIceCandidateFromServer',iceCandidate=>{
                console.log('receivedIceCandidateFromServer')
                addIceCandidate(iceCandidate)
                console.log(iceCandidate)
            })

            
            const offer = await peerConnectionRef.current.createOffer({offerToReceiveAudio: true, offerToReceiveVideo: true});
            await peerConnectionRef.current.setLocalDescription(offer);
            setDidOffer(true);
            console.log(offer.type);
            socketRef.current.emit('newOffer', offer);
        } catch (err) {
            console.error('Error during video call offer:', err);
        }
    };


    const createPeerConnection = () => {
        console.log(userName)
        peerConnectionRef.current = new RTCPeerConnection(peerConfiguration);

        peerConnectionRef.current.ontrack = (event) => {
            remoteStreamRef.current = new MediaStream();
            remoteVideoRef.current.srcObject = remoteStreamRef.current;
            event.streams[0].getTracks().forEach(track => {
                remoteStreamRef.current.addTrack(track);
            });
            if(remoteVideoRef.current) {
            console.log(remoteVideoRef)
            }
        };

        peerConnectionRef.current.onicecandidate = (event) => {
            
            if (event.candidate) {
                const candidateType = event.candidate.type;
                
                // Check if it’s a relay candidate
                if (candidateType === 'relay') {
                    console.log("Relay candidate found:", event.candidate);
                }
                socketRef.current.emit('sendIceCandidateToSignalingServer', {
                    iceCandidate: event.candidate,
                    iceUserName: userName,
                    didOffer
                });
            }
        };
    };

    const handleAnswer = async (answerObj) => {
        console.log("ANSWER", answerObj);
    
        if (!peerConnectionRef.current) {
            console.error("PeerConnection not initialized");
            return;
        }
    
        try {
            if (peerConnectionRef.current.signalingState === "stable") {
                console.warn("Signaling state is stable, resetting the peer connection.");
    
                // Close the existing peer connection
                peerConnectionRef.current.close();
                peerConnectionRef.current = null;
    
                // Recreate the peer connection
                console.log(peerConnectionRef.current.signalingState)
            }
            // Check the signaling state before setting the remote description
            if (peerConnectionRef.current.signalingState !== "have-local-offer") {
                console.warn("Cannot set remote answer: Signaling state is", peerConnectionRef.current.signalingState);
                return;
            }
    
            await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answerObj.answer));
            console.log("Remote description set successfully");
    
        } catch (err) {
            console.error('Error handling answer:', err);
        }
    };

    const addIceCandidate = (iceCandidate) => {
        try {
            peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(iceCandidate));
        } catch(err) {
            console.error(err)
        }
    };

    const handleHangUp = () => {
        // Close the peer connection
        if (peerConnectionRef.current) {
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
            console.log("Peer connection closed.");
        }
    
        // Stop all local media tracks
        if (localStreamRef.current) {
            localStreamRef.current.getTracks().forEach(track => track.stop());
            localStreamRef.current = null;
            console.log("Local media tracks stopped.");
        }
    
        // Inform the other peer that the call has ended
        if (socketRef.current) {
            socketRef.current.emit('hangup');
            console.log("Hangup signal sent to other peer.");
        }
    };

    return (
        <div>
            <div className='extra-content-container-video'>
                <h1>Backend Peer Connection Test</h1>
                <div className='main-container-video'>
                    <video ref={localVideoRef} autoPlay playsInline></video>
                    <video ref={remoteVideoRef} autoPlay playsInline></video>
                </div>
                <button className="btn btn-primary col-1" onClick={HandleVideoCall}>Call!</button>
                <button className="col-1 btn btn-primary" onClick={handleHangUp}>Hangup</button>
            </div>
        </div>
    )
}