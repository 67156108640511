import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext({
    isDarkMode: false,
    setIsDarkMode: () => { }
});

export const ThemeProvider = ({ children }) =>
{
    const [isDarkMode, setIsDarkMode] = useState(() =>
    {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? JSON.parse(savedTheme) : false;
    });

    useEffect(() =>
    {
        localStorage.setItem('theme', JSON.stringify(isDarkMode));
    }, [isDarkMode]);

    return (
        <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;