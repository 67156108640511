import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Navbar from "../Navbar"
import { sendPasswordResetEmail } from "firebase/auth"; // Only need sendPasswordResetEmail
import { auth } from "../../firebase/firebase";
import './ForgotPassword.css'
import ThemeContext from '../ThemeContext';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    // Going to need to add error handling once api is set up
    const [error, setError] = useState("");
    const [callError, setCallError] = useState(false);

    const  navSignUp= () => {
        navigate('/sign-up');
    };
    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            // Call API
        } catch (error) {
            // Catch api error
            setError(error.message);
            setCallError(true);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        try {
            // Call Firebase's sendPasswordResetEmail method
            await sendPasswordResetEmail(auth, email);
            navigate('/sign-up');
            alert("Password reset email sent!");
        } catch (error) {
            setError(error.message);
            setCallError(true);
        }
    };

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className='forgot-container'>
                <div className='branded-container'>
                    <form onSubmit={handleSubmit} className='branded-forgot-container'>
                        <div className='signup-title-container'>
                            <h1 className="signup-title">Forgot Password?</h1>
                        </div>
                        <div className='instructions-container'>
                            <p>
                            Please provide the email used to create your account and will send account retrieval instructions! 🤠
                            </p>
                        </div>
                        <div className='forgot-input-container'>
                            <div className='branded-input'>
                                <TextField
                                    className='custom-width'
                                    id="outlined-basic" 
                                    label="Email" 
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} 
                                />
                            </div>

                            <button className="remember-password-link" onClick={navSignUp}>Remember Your Password?</button>

                            <div className='forgot-button'>
                                <button type="submit" className='branded-button'>Submit</button>
                            </div>
                        </div>
                    </form>   
                </div>     
             </div>
        </div>
    )
}