import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'; // Import necessary hooks
import { Triangle } from 'react-loader-spinner';
import { Oval } from 'react-loader-spinner';
import { render } from 'react-dom';
import './Demo.css'; // Import CSS for styling the component
import WebcamComponent from '../Webcam'; // Import the WebcamComponent
import { CameraButton, SupportButton, RecordButton, SendToTranslateButton } from '../Button'; // Import UI buttons
import Navbar from "../Navbar"; // Import the Navbar component
import MediaRecorderComponent from '../MediaRecorder'; // Adjust the path according to where it's located
import NewVideoPreview from '../NewVideoPreview'; // Adjust the path according to where it's located
import axios from 'axios';  // Import axios for making HTTP requests
import ThemeContext from '../ThemeContext';
import Lpp from "../Lpp"
import ExtraContent from './ExtraContent';

export default function Demo() {
    const [output, setOutput] = useState('');
    const [isCameraActive, setCameraActive] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [videoData, setVideoData] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const videoRef = useRef(null);
    const [translatedWords, setTranslatedWords] = useState(null);
    const [stream, setStream] = useState(null);
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';
    const [isLoading, setIsLoading] = useState(false);

    // Toggles the camera on/off by flipping the isCameraActive state
    const toggleCamera = useCallback(() => {
        setCameraActive(prevState => !prevState); // Toggles the camera state
        setOutput('');
    }, []);

    // Start recording by ensuring the stream is available and setting isRecording to true
    const handleStartRecording = useCallback(() => {
        setOutput('');
        if (stream) {
            setIsRecording(true); // Start recording if the stream is available
        } else {
            console.error('Stream is not available'); // Error message if stream is missing
        }
    }, [stream]);

    // Called when the recording is complete, storing the recorded video data
    const handleRecordingComplete = useCallback((videoInfo) => {
        console.log('Recording data after media recorder', videoInfo.videoURL);
        setVideoData(videoInfo); // Store video data in the state
        setIsRecording(false); // Stop the recording
        console.log('Recorded chunks:', videoInfo.recordedChunks); // Debugging the video chunks
    }, []);

    // Cancels the recorded video, resetting videoData to null
    const handleCancel = useCallback(() => {
        setVideoData(null); // Reset video data
    }, []);

    // Receives and processes the media stream (camera stream)
    const handleStream = useCallback((newStream) => {
        console.log('Stream received:', newStream); // Debugging the stream data
        if (videoRef.current) {
            videoRef.current.srcObject = newStream; // Set the videoRef's srcObject to the stream
            setStream(newStream); // Save the stream in state
        }
    }, []);

    // Handles uploading the recorded video to the EC2 instance
    const handleVideoUpload = async () => {
        console.log("Submit button clicked!");

        // Check if there's video data available
        if (videoData && videoData.videoBlob) {
            const formData = new FormData();  // Create FormData object
            formData.append('file', videoData.videoBlob);  // Append the videoBlob to FormData
            console.log('Uploading video file:', videoData.videoBlob);

            try {
                console.log('Submit video has been clicked');
                setIsLoading(true);
                // Make the request to the EC2 endpoint
                const response = await axios.post('https://asl-live.com:8080/upload_video', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Ensure the correct content type for file uploads
                    },
                    onUploadProgress: (progressEvent) => {
                        // Optional: Track upload progress (percentage)
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(progress);  // Update progress state
                        console.log(`Upload progress: ${progress}%`);  // Log the upload progress
                    }
                });

                // Handle the successful upload response
                console.log('Video uploaded successfully:', response.data);
                setIsLoading(false);

                const words = response.data.translatedWords || response.data;  // Assuming 'translatedWords' contains the words in the response
                const res = await Lpp(words)
                setOutput(res.choices[0].message.content);;
                console.log("TRANSLATED SENTENCE:", output);

                setIsRecording(false);

                //setCameraActive(false);

                // Reset the upload progress and clear video data after upload
                setUploadProgress(0);
                showSubmissionOverlay();  // Show a success message overlay
                handleCancel();  // Clear the video data after successful upload
            } catch (error) {
                // Detailed error handling
                setIsLoading(false);
                if (error.response) {
                    console.error('Server responded with an error:', error.response.data);
                } else if (error.request) {
                    console.error('No response was received:', error.request);
                } else {
                    console.error('Error setting up the request:', error.message);
                }
            }
        } else {
            setIsLoading(false);
            console.error('No video file provided for upload');
        }
    };


    // Displays an overlay indicating successful submission for 4 seconds
    const showSubmissionOverlay = () => {
        setShowOverlay(true); // Show the overlay
        setTimeout(() => {
            setShowOverlay(false); // Hide the overlay after 4 seconds
        }, 4000);
    };

    // Render the component
    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar /> {/* Navbar component at the top */}
            <div className="demo-page">
                <div className='centered'>
                    {isLoading &&
                        <div className='loading-overlay'>
                            <Triangle
                                className="loading-center"
                                visible={true}
                                height="80"
                                width="80"
                                color="#fff"
                                ariaLabel="triangle-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>

                    }
                </div>
                <h1 className="demo">Our Tool in Action</h1>
                <h2>Make sure to put your hands out of the frame after every sign!</h2>
                {/* <h3><i>Click "Turn Camera On" below to use our translation tool</i></h3> */}
                {/* Webcam component to capture the media stream */}
                <WebcamComponent
                    isActive={isCameraActive}  // Pass the camera activation state
                    videoRef={videoRef}        // Pass the video reference
                    onUserMedia={handleStream} // Pass the function to handle the media stream
                />
                <div className="submit-buttons-in-demo-div">
                    {output
                        && <div className='main-container'>{output}</div>
                    }
                    {/* Camera toggle button when not recording */}
                    {
                        !isRecording && (
                            <CameraButton onClick={toggleCamera} buttonStyle="btn--outline">
                                {isCameraActive ? 'Camera Off' : 'Camera On'} {/* Conditional button text */}
                            </CameraButton>
                        )
                    }

                    {/* Show the record button only if the camera is active, not recording, and no video preview */}
                    {isCameraActive && !isRecording && !videoData && (
                        <RecordButton onClick={handleStartRecording} buttonStyle="btn--outline">
                            Record Clip {/* Button to start recording */}
                        </RecordButton>
                    )}

                    {/* Conditionally render MediaRecorderComponent when recording starts */}
                    {isRecording && stream && (
                        <MediaRecorderComponent
                            stream={stream}  // Pass the media stream
                            onRecordingComplete={handleRecordingComplete}  // Callback for when recording is complete
                            stopRecordingTimeoutDuration={10000}
                        />
                    )}

                    {/* After recording, show video preview with options to cancel or send to EC2 */}
                    {videoData && (
                        <NewVideoPreview
                            videoUrl={videoData.videoURL}  // Show the recorded video URL
                            onCancel={handleCancel}       // Allow user to cancel the video
                        >
                            <SendToTranslateButton
                                handleVideoUpload={handleVideoUpload} // Pass the upload function
                                hasRecordedChunks={!!videoData}      // Enable button if video data exists
                                buttonStyle="btn--primary"
                                buttonSize="btn--medium"
                            />
                        </NewVideoPreview>
                    )}
                </div>
            </div>
        </div>
    );
}
