import React, { useEffect, useRef, useState } from 'react';
import './Webcam.css';
import { IoCameraReverse } from "react-icons/io5";

const WebcamComponent = ({ isActive, onUserMedia, onUserMediaError, videoRef }) =>
{
  const [useFrontCamera, setUseFrontCamera] = useState(true); // State to toggle camera
  const mediaStream = useRef(null);

  useEffect(() =>
  {
    async function enableStream()
    {
      if (isActive)
      {
        const cameraConfig = {
          video: {
            facingMode: useFrontCamera ? "user" : "environment" // Toggle between front and back
          }
        };
        try
        {
          const stream = await navigator.mediaDevices.getUserMedia(cameraConfig);
          mediaStream.current = stream;
          if (videoRef.current)
          {
            videoRef.current.srcObject = stream;
            if (onUserMedia)
            {
              onUserMedia(stream);
            }
          }
        } catch (error)
        {
          console.error('Error accessing the webcam:', error);
          if (onUserMediaError)
          {
            onUserMediaError(error);
          }
        }
      } else
      {
        if (mediaStream.current)
        {
          mediaStream.current.getTracks().forEach(track => track.stop());
          mediaStream.current = null;
        }
        if (videoRef.current)
        {
          videoRef.current.srcObject = null;
        }
      }
    }

    enableStream();

    return () =>
    {
      if (mediaStream.current)
      {
        mediaStream.current.getTracks().forEach(track => track.stop());
        mediaStream.current = null;
      }
      if (videoRef.current)
      {
        videoRef.current.srcObject = null;
      }
    };
  }, [isActive, useFrontCamera, onUserMedia, onUserMediaError, videoRef]);

  return (
    <div className="webcam-container">
      {isActive ? (
        <>
          <video ref={videoRef} autoPlay playsInline muted className="webcam-video">
            Webcam stream not available.
          </video>
          <button onClick={() => setUseFrontCamera(!useFrontCamera)} className="toggle-camera">
            <IoCameraReverse />
          </button>
        </>
      ) : (
        <div className="webcam-inactive">
          Your camera is off
        </div>
      )}
    </div>
  );
};

export default WebcamComponent;