import React, { useRef }from 'react';
import './Contact.css';
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import emailjs from 'emailjs-com';

export default function Contact()
{
    const form = useRef();

    const sendEmail = (e) =>
    {
        e.preventDefault();

        emailjs
            .sendForm('service_ouykkvz', 'template_k3vmeei', form.current, 'xo2ZA6xdyBtsVGqAd')
            .then(
                () =>
                {
                    console.log('SUCCESS!');
                },
                (error) =>
                {
                    console.log('FAILED...', error.text);
                },
            );

        e.target.reset();
    };

    const style = isMobile ? { color: "black" } : { color: "white" }
    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <input type="text" name="name" placeholder="Your Name" required />
                <input type="email" name="email" placeholder="Your Email" required />
                <input name="message" placeholder="Your Message" required></input>
                <button type="submit">Send Message</button>
            </form>
            <p className='support-sub-title'>If you would like to help us out by providing more datasets to make our product even better, please reach out to us.</p>
            <div className="contact-icons">
                <div className="github-icon">
                    <FaGithub style={style} size={48} onClick={() => window.open("https://github.com/ASL-Live/ASLReader", "_blank")} />
                </div>
                <div className="linkedin-icon">
                    <FaLinkedin style={style} size={48} onClick={() => window.open("https://www.linkedin.com/groups/13097723/", "_blank")} />
                </div>
            </div>
        </div>
    );
}