import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
import './GetInvolved.css';
import WebcamComponent from '../Webcam';
import { CameraButton, RecordButton, SendToCloudButton, InstructionButton } from '../Button';
import NewVideoPreview from '../NewVideoPreview';
import MediaRecorderComponent from '../MediaRecorder';
import SubmissionSuccess from '../SubmissionSuccess';
import InstructionModal from '../InstructionModal';
import Navbar from "../Navbar"
import WordList from '../WordList';
import ThemeContext from '../ThemeContext';
import SignupModal from '../SignupModal';

export default function GetInvolved() {
    const [isCameraActive, setCameraActive] = useState(false);
    const [isRecording, setRecording] = useState(false);
    const [videoData, setVideoData] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);  // Progress state
    const [isUploading, setUploading] = useState(false);  // Uploading state
    const [showOverlay, setShowOverlay] = useState(false); // to toggle successful submission box
    const [folders, setFolders] = useState([]);
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState('');  // the folder that will have submissions (default) folder 
    const [showModal, setShowModal] = useState(false);
    const [showModalSignup, setShowModalSignup] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const openSignupModal = () => setShowModalSignup(true);
    const closeSignupModal = () => setShowModalSignup(false);


    // Toggle camera on/off
    const toggleCamera = useCallback(() => {
        setCameraActive(prevState => !prevState);
    }, []);

    const checkLoginStatus = () => {
        console.log(sessionStorage.isLoggedIn)
        if (sessionStorage.isLoggedIn === 'true') {
            handleStartRecording();
        } else {
            console.log("OPEN MODAL")
            openSignupModal();
        }
    }

    // Start recording
    const handleStartRecording = useCallback(() => {
        if (stream) {
            setRecording(true);
        } else {
            console.error('Stream is not available');
        }
    }, [stream]);

    // Handle recording completion
    const handleRecordingComplete = useCallback((videoInfo) => {
        console.log('recording data after media recorder', videoInfo.videoURL);

        setVideoData(videoInfo);
        setRecording(false);
        console.log('r', videoInfo.recordedChunks);
    }, []);

    // Handle cancellation of recorded video
    const handleCancel = useCallback(() => {
        setVideoData(null);
    }, []);

    // Handle the media stream
    const handleStream = useCallback((newStream) => {
        console.log('Stream received in GetInvolved:', newStream);
        if (videoRef.current) {
            videoRef.current.srcObject = newStream;
            setStream(newStream); // Set the stream state here
        }
    }, []);


    // uploads the video 
    const handleVideoUpload = async () => {
        console.log("Submit button clicked!");
        if (videoData && videoData.videoBlob && !isUploading) {
            try {
                setUploading(true); // Disable submit button during upload
                const storage = getStorage();
                const fileName = `video_${Date.now()}.mp4`;  // Generate a unique file name
                const videoRef = ref(storage, `${selectedFolder}/${fileName}`);

                // Upload the video file to Firebase Storage
                const uploadTask = uploadBytesResumable(videoRef, videoData.videoBlob);

                // Monitor the upload progress
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Calculate upload progress percentage
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                        console.log("Upload is " + progress + "% done");
                    },
                    (error) => {
                        // Error handling and feedback
                        console.error("Error uploading video:", error);
                        alert("There was an error uploading your video. Please try again.");  // User feedback on error
                        setUploading(false);  // Re-enable submit button after error
                    },
                    async () => {
                        // Upload successful
                        console.log("Video uploaded successfully.");
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        console.log("Download URL:", downloadURL);
                        //alert("Your video has been uploaded successfully!");  // Success feedback to user
                        setUploadProgress(0);  // Reset progress
                        setUploading(false);  // Re-enable submit button
                        // do a temp overlay here to let the user know that they have submitted

                        showSubmissionOverlay();
                        handleCancel();
                    }
                );
            } catch (error) {
                console.error("Error during video upload:", error);
                alert("Unexpected error occurred during upload.");  // Catch block error feedback
                setUploading(false);  // Re-enable submit button on error
            }
        } else {
            console.log("No video file to upload");
        }
    };

    // Displays the submission success for 4 secs after uploading
    const showSubmissionOverlay = () => {
        setShowOverlay(true);
        setTimeout(() => {
            setShowOverlay(false);
        }, 4000);
    }

    const handleFolderSelect = (folderName) => {
        console.log("Selected folder:", folderName);
        setSelectedFolder(folderName);
    };

    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className="getInvolved-page">
                <div className='titles-only'>
                    <h1>Get Involved with Us</h1>
                    <h2>Help us by submitting some of your clips!</h2>
                    <div className="content-container">
                        <div className="word-list-container">
                            <WordList onFolderSelect={handleFolderSelect} />
                        </div>

                        <div className="webcam-and-controls">
                            <WebcamComponent

                                isActive={isCameraActive}
                                videoRef={videoRef}
                                onUserMedia={handleStream}
                            />

                            {!selectedFolder && isCameraActive && (
                                <div className="error-msg">
                                    Please select a word from the list
                                </div>
                            )}

                            <div className="submit-buttons-div">
                                {!isRecording && (
                                    <CameraButton onClick={toggleCamera} buttonStyle="btn--outline">
                                        {isCameraActive ? 'Camera Off' : 'Camera On'}
                                    </CameraButton>
                                )}

                                {!showModal && (
                                    <InstructionButton
                                        openModal={openModal}
                                        buttonStyle="btn--primary"
                                        buttonSize="btn--small"
                                    />
                                )}
                                <InstructionModal show={showModal} onClose={closeModal} />

                                {/* Record Button: Disabled unless a folder is selected and the camera is active */}
                                {isCameraActive && !isRecording && !videoData && (
                                    <RecordButton
                                        onClick={checkLoginStatus}
                                        buttonStyle="btn--outline"
                                        disabled={!selectedFolder}  // Disable if no folder is selected
                                    >
                                        Record Clip
                                    </RecordButton>
                                )}

                                {/* Conditionally render MediaRecorderComponent when recording starts */}
                                {isRecording && stream && (
                                    <MediaRecorderComponent
                                        stream={stream}
                                        onRecordingComplete={handleRecordingComplete}
                                        stopRecordingTimeoutDuration={3000}
                                    />
                                )}

                                {/* After recording, show video preview with options to cancel or send to cloud */}
                                {videoData && (
                                    <NewVideoPreview
                                        videoUrl={videoData.videoURL}
                                        onCancel={handleCancel}
                                    >
                                        <SendToCloudButton
                                            handleVideoUpload={handleVideoUpload} // Use handleVideoUpload here
                                            hasRecordedChunks={!!videoData}      // Ensure button is enabled only if videoData exists
                                            buttonStyle="btn--primary"
                                            buttonSize="btn--medium"
                                        />
                                    </NewVideoPreview>
                                )}
                            </div>
                        </div>
                        <div className="blurb-div">
                            <SignupModal show={showModalSignup} onClose={closeSignupModal} />
                        </div>
                        <div className="empty-div-right">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
