import React, { useContext } from 'react'
import '../../App.css';
import './Home.css';
import Cards from '../Cards';
import Footer from '../Footer';
import Typed from 'typed.js';
import { DemoButton, InfoButton } from '../Button'
import Navbar from "../Navbar"
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SvgIcon from '@mui/material/SvgIcon';
import ThemeContext from '../ThemeContext';

function Home()
{
    const el = React.useRef(null);
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    React.useEffect(() =>
    {
        const typed = new Typed(el.current, {
            strings: ['ASL-LIVE^1000', 'Translate ASL to Text <br> in <em> Real Time.</em>'],
            typeSpeed: 50,
        });

        return () =>
        {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);
    return (
        <div className={`home-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                <Navbar />
                <div className='intro-container'>
                    <div className='text-container'>
                        <span className="home-title" ref={el} />
                    </div>
                </div>

                <div className='hero-btns'>
                    <InfoButton className="btns" buttonStyle='btn--outline' buttonSize='btn--large'>
                        <span>More Info</span>
                    </InfoButton>
                    <DemoButton className="btns" buttonStyle='btn--primary' buttonSize='btn--large'>
                        <span>Try It Out! <i className='far fa-play-circle' /></span>
                    </DemoButton>
                </div>
                <div className="bottom-arrow">
                    <img class="arrow" src="https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-128.png" />
                </div>
                <Cards />
                <Footer />
            </div>
        </div>
    );
}

export default Home;