import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LoginButton } from './Button';
import './Navbar.css';
import { RiMoonClearLine } from "react-icons/ri";
import { TbHandTwoFingers } from "react-icons/tb";
import { MdOutlineWbSunny } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import ThemeContext from './ThemeContext';

function Navbar()
{
    const navigate = useNavigate();
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);
    const toggleTheme = () =>
    {
        setIsDarkMode(prevMode => !prevMode);
    };

    const showButton = () =>
    {
        if (window.innerWidth <= 960)
        {
            setButton(false);
        }
        else
        {
            setButton(true);
        }
    };
    function getButtonText()
    {
        return sessionStorage.isLoggedIn === 'true' ? "Log Out" : "LOGIN/SIGNUP";
    }
    function handleLogOut()
    {
        if (sessionStorage.isLoggedIn === 'true')
        {
            sessionStorage.setItem('isLoggedIn', false);
            sessionStorage.setItem('user', null);
            navigate('/');
        } else
        {
            navigate('/sign-up');
        }
    }

    useEffect(() =>
    {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className={`navbar ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                <div className="navbar-container">
                    <div className='navbar-logo-container'>
                        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                            ASL-Live <TbHandTwoFingers className="navbar-icon" />
                        </Link>
                    </div>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? "fa-solid fa-times" : "fa-solid fa-bars"} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        {!button &&
                            <li className='nav-item'>
                                <Link to="/sign-up" className='nav-links' onClick={closeMobileMenu && handleLogOut}>
                                    {getButtonText()}
                                </Link>
                            </li>
                        }
                        <li className='nav-item'>
                            <Link to="/" className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to="/about" className='nav-links' onClick={closeMobileMenu}>
                                About Us
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-links' onClick={toggleTheme}>
                                {isDarkMode ? <MdOutlineWbSunny /> : <RiMoonClearLine />}
                            </a>
                        </li>
                    </ul>
                    {button && <LoginButton buttonStyle='btn--outline' onClick={handleLogOut}>{getButtonText()}</LoginButton>}
                </div>
            </nav>
        </>
    );
}

export default Navbar;
