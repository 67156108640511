import React from 'react';
import './InstructionModal.css'; // Import the CSS file

const InstructionModal = ({ show, onClose }) =>
{
  if (!show)
  {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h3>To contribute to our database:</h3>
        <p>
          1. Select a word from the list and turn on your camera.<br />
          2. Click the record button. You’ll have 3 seconds to prepare and record your sign.<br />
          3. Replay the video to check for clarity.<br />
          4. Submit or cancel the video when you're done.
        </p>
        <button className="btn btn--primary btn--small" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default InstructionModal;

