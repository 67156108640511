import React from 'react'
import CardItem from './CardItem'
import './Cards.css';

function Cards()
{
    return (
        <div className='cards'>
            <h1>Help us Build the Future of ASL Translation</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                    {/* if you want to add more cards in a single row, make sure to wrap them 
                    around ul tags.
                    if you want to change where the card takes you, change the path */}
                    <ul className="cards__items">
                        <CardItem
                            src='images/webcam.png'
                            text='Want to contribute to our research? Submit your own videos to help us improve our ML model.'
                            label='Submit Videos'
                            path='/get-involved'
                        />
                        <CardItem
                            src='images/hands.jpg'
                            text='In this tutorial, we will show you how to use ASL-Live and what are the best practices for this tool.'
                            label='Tutorial'
                            path='/tutorial'
                        />
                        <CardItem
                            src='images/support.jpg'
                            text='Do you have any questions about our tool? Feel free to reach out to us for any issues you may have!'
                            label='Support + FAQs'
                            path='/support'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards;
