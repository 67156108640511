import React, { useState, useEffect } from 'react';
import './Authors.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const authors = [
    {
        avatar: "/images/renato.jpg",
        name: 'Renato Diaz',
        review: 'Renato is a Software Engineer from UCF with a strong enthusiasm for all things tech. His journey in tech began in high school when he first explored Computer Science, igniting a passion for creating innovative tech solutions. He has a special talent for crafting robust backend systems and designing visually appealing, responsive user interfaces. Outside of his academic pursuits, he is an avid traveler, always eager to explore new places and savor exotic cuisines. One of his ultimate dreams is to own a Porsche 911 Turbo S and drive it on the Nürburgring in Germany.'
    },
    {
        avatar: "/images/gino.jpg",
        name: 'Gino Orlandini',
        review: 'Gino is a Software Engineer from UCF with a passion for combining creativity and technology. He is experienced in front-end frameworks, data science, and game design, often working on projects that blend technical skill with artistic expression. Whether he\'s building machine learning models or designing websites, Gino enjoys bringing ideas to life and solving complex problems. When he’s not coding, you’ll find him playing guitar or on the soccer field, where he draws inspiration for his creative and problem-solving pursuits in both work & life.'
    },
    {
        avatar: "/images/noah.jpg",
        name: 'Noah Beightol',
        review: `Noah is a Computer Science major at the University of Central Florida. Noah is passionate about creating accessible products that help revolutionize people's lives. His contributions to ASL-Live include developing the Language Post Processing (LPP) architecture and helping build the project's frontend. In his free time, Noah loves backpacking, scuba-diving, weight-lifting, and collecting LEGO’s. After graduation, Noah plans to continue his passion for software development by working as a full-stack software engineer in Pittsburgh.`
    },
    {
        avatar: "/images/will.jpg",
        name: 'William Fields',
        review: 'William is a full-stack and machine learning software engineer with a passion for solving complex problems through innovative technology. He specializes in building scalable web apps using React & Node.js; leveraging tools like TensorFlow and AWS for ML models. He has developed an ASL video classification system and cloning a popular streaming platform, which allowed him to combine his expertise in both front-end and back-end development with data-driven decision-making. In his free time, he loves to practice hard leetcode problems.'
    },
    {
        avatar: "/images/megan.jpg",
        name: 'Megan Morgan',
        review: 'Megan is a Software Engineer from UCF with plans to join the leading global security, defense, and aerospace contractor as a systems engineer in the future. She has a strong grasp of data structures and algorithms and actively seeks opportunities to automate processes using Python or PowerShell. Megan loves problem-solving and writing code to tackle complex challenges. Outside of her studies, she enjoys reading, especially works by her favorite author Stephen King, along with rock climbing, snowboarding, and training jiu jitsu.'
    },
    {
        avatar: "/images/leo.jpg",
        name: 'Leo Pariano',
        review: 'Leonardo is a Software Engineer with experience in tech support and software development. At Geek Squad, he resolved technical issues and performed hardware repairs to enhance device performance. As a Software Engineer Intern at Rekndle, he developed Shopify APIs and automated inventory processes. Leonardo is skilled in Python, TensorFlow, and real-time translation technologies. He has a Bachelor\'s in Computer Science and is a purple belt in Brazilian jiu-jitsu, reflecting his discipline and dedication both in and out of the workplace.'
    }
];

function Authors()
{
    const [slidesPerView, setSlidesPerView] = useState(3);

    useEffect(() =>
    {
        function handleResize()
        {
            if (window.innerWidth < 768)
            {
                setSlidesPerView(1);
            } else if (window.innerWidth < 1024)
            {
                setSlidesPerView(2);
            } else
            {
                setSlidesPerView(3);
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section id='authors-section'>
            <h2>Meet The Team</h2>
            <div className='authors-container'>
                <Swiper className="mySwiper"
                    modules={[Pagination]}
                    slidesPerView={slidesPerView}
                    spaceBetween={30}
                    pagination={{
                        clickable: true
                    }}>
                    {
                        authors.map(({ avatar, name, review }, index) =>
                        {
                            return (
                                <SwiperSlide key={index} className='author'>
                                    <div className="client-avatar">
                                        <img src={avatar} alt={name} />
                                    </div>
                                    <h3 className='client-name'>{name}</h3>
                                    <small className='client-review'>
                                        {review}
                                    </small>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </section>
    );
}

export default Authors;