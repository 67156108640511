import React, {useContext} from 'react'
import './About.css'
import '../Button.css'
import Navbar from "../Navbar"
import { LearnMoreButton } from '../Button';
import Authors from '../Authors';
import ThemeContext from '../ThemeContext';

export default function About()
{
    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className="about-page">
            <div className='about-container'>
                    <img src="/images/group.jpg" alt="Group Picture" className="about-image" />
                    <div className="about-content">
                        <h1>Who Are We?</h1>
                        <h2>Our Motivation</h2>
                        <p>&emsp;We are a group of researchers, engineers, and sign language enthusiasts dedicated to supporting the Deaf & Hard of Hearing Community. Our goal is to make an educational mobile tool that anyone can use to learn ASL and/or communicate with others who use ASL.<br /><br />&emsp; Our story begins in the city of Orlando at the <strong>University of Central Florida</strong>, where by chance, a group of restless Computer Science students formed to work on a new groundbreaking tool that could change the lives of not only the members of the Deaf Communities, but also anyone interested in learning more about this culture forever. What started off as a Computer Science Senior Design project at UCF, our tool has blossomed into so much more. We are also proud to create the most robust ASL dataset in the US! Our goal is to be inclusive to everyone. We want to bridge the gap, not make it wider!</p>
                        <LearnMoreButton buttonStyle="btn--primary" buttonSize="btn--large">
                            Learn More
                        </LearnMoreButton>
                    </div>
                </div>
                <Authors />
            </div>
        </div>
        
    );
}