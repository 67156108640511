import React, { useEffect, useState } from 'react';

const MediaRecorderComponent = ({ stream, onRecordingComplete, stopRecordingTimeoutDuration = 10000 }) => {
  const [recorder, setRecorder] = useState(null);
  const [countdown, setCountdown] = useState(3); // state for timer
  const [isRecording, setIsRecording] = useState(false);


  useEffect(() => {
    if (stream) {
      console.log('MediaRecorder received stream:', stream);

      // Initialize MediaRecorder with the stream
      const newRecorder = new MediaRecorder(stream);
      setRecorder(newRecorder);

      let recordedChunks = [];

      newRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };

      newRecorder.onstop = () => {
        // Create video blob and URL after stopping the recording
        const videoBlob = new Blob(recordedChunks, { type: 'video/mp4' });
        const videoURL = URL.createObjectURL(videoBlob);

        console.log('Recording stopped, created video URL:', videoURL);
        console.log('video chunks', recordedChunks.length);
        console.log('video blob size', videoBlob.size);

        // Call the callback to pass the video data
        onRecordingComplete({ videoURL, videoBlob, recordedChunks });
      };

      // Start recording
      //newRecorder.start();
      //console.log('Recording started...');

      // Countdown logic
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(countdownInterval);
            newRecorder.start();  // Start recording after the countdown reaches 0
            setIsRecording(true);
            console.log('Recording started...');
            return 0;  // Countdown ends
          }
          return prevCountdown - 1;  // Decrease the countdown each second
        });
      }, 1000);

      // Stop recording after 4(ish) seconds
      const stopRecordingTimeout = setTimeout(() => {
        if (newRecorder.state === 'recording') {
            newRecorder.stop();
            console.log('Recording stopped...');
        }
      }, stopRecordingTimeoutDuration + countdown * 1000);

      // Cleanup the media recorder when component unmounts
      return () => {
        if (newRecorder.state === 'recording') {
            newRecorder.stop();
            clearInterval(countdownInterval);
            clearTimeout(stopRecordingTimeout);
        }
      };
    }
  }, [stream, onRecordingComplete]);

  return (
    <div>
      {/* Display countdown when not recording */}
      {!isRecording && countdown > 0 ? (
        <div className="countdown-timer">
          <h2>Recording will start in: {countdown}</h2>
        </div>
      ) : isRecording ? (
        <div className="recording-indicator">
          <h2>Recording...</h2>
        </div>
      ) : (
        <div>Recording complete.</div>
      )}
    </div>
  );

};

export default MediaRecorderComponent;
