import React, { useState, useContext } from 'react';
import './Support.css';
import Contact from '../Contact';
import Navbar from '../Navbar';
import ThemeContext from '../ThemeContext';

const faqData = [
    { question: "Who are we?", answer: "We are a group of researchers, engineers, and sign language enthusiasts dedicated to supporting the Deaf & Hard of Hearing Community." },
    { question: "Why did we make ASL-Live?", answer: "We wanted make an educational tool that anyone can use to learn ASL and/or communicate with others who use ASL." },
    { question: "How does ASL-Live work?", answer: "ASL-Live uses machine learning to interpret sign language gestures and provide translations in real-time." },
    { question: "Does ASL-Live cost money?", answer: "ASL-Live is free to use, unless a tech giant ever wants to buy us; only then will we absolutely retire for life." },
    { question: "Why do I need to provide video clips?", answer: "Although it is entirely optional, video clips help us improve our machine learning model and provide better translations. You can be part of something big." },
    { question: "My camera isn't working. What should I do?", answer: "Please check your browswer settings and make sure your camera is enabled for ASL-Live." },
    { question: "How do I report a bug or provide feedback?", answer: "You can do all of these by contacting our support team on this page!" },
    { question: "Will you ever sell my data?", answer: "We will never sell your data to third parties. Your privacy is important to us." },
    { question: "I find this offensive. What should I do?", answer: "Please contact our support team with details about the issue." },
];

export default function Support()
{
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) =>
    {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const { isDarkMode } = useContext(ThemeContext);
    const pageClass = isDarkMode ? 'dark-mode' : 'light-mode';

    return (
        <div className={`background ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navbar />
            <div className="support-page">
                <h1 className="support-title">Frequently Asked Questions</h1>
                <h2 className="support-title">Reach out to our Support team for any inquiries</h2>
                <div>
            </div>
            
            <div className="support-layout">
                <div className="faq-container">
                    {faqData.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <button className="faq-question" onClick={() => handleToggle(index)}>
                                {faq.question}
                            </button>
                            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
                <Contact />
            </div>
        </div>
        </div> 
    );
}