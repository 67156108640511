import React from 'react';
import './BlurbModal.css';
import { useNavigate } from 'react-router-dom';


const SignupModal = ({ show, onClose }) =>
{   
    const navigate = useNavigate();
    function Signup() {
        navigate('/sign-up');
    }
    
    if (!show)
    {
        return null;
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2 className='modal-header'>Join Our Community!</h2>
                <p>Create an account to help us expand our ASL database by contributing video clips. Your participation will enhance our model's accuracy and help countless others learn ASL!</p>
                <div className='two-button'>
                    <button className="btn pair-button btn--primary btn--small" onClick={Signup}>Join</button>
                    <button className="btn pair-button btn--primary btn--small" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default SignupModal;